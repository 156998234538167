import React from "react";
import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";


/* 
 * component CustomModal
 *
 * ********************************/
class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      title: '',
      description: '',
      completed: false
    };
  }

  componentDidMount() {
    if (this.props.isEdit && this.props.item) {
      this.setState({
        title: this.props.item.title,
        description: this.props.item.description,
        completed: this.props.item.completed    
      });
    }
  }

  handleChange(e) {
    if( e.target.name === "title" || e.target.name === "description") {
      this.setState({
        [e.target.name] : e.target.value
      });
    }

    if (e.target.name === "completed") {
      this.setState({
        completed: e.target.checked
      });
    }    
  }

  handleClick(e) {
    const item = this.state;

    if (e.target.name === "save-button") {
      if (item.title.trim() === "" || item.description.trim() === "") {
        window.alert("내용을 입력하세요.");
        return;
      }

      e.target.item = item;
      this.props.onSaveButtonClick(e);
    }
  }

  render() {
    const toggle = this.props.toggle;
    const title = this.state.title;
    const description = this.state.description;
    const completed = this.state.completed;

    return (
      <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}> Todo Item </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                value={title}
                onChange={this.handleChange}
                placeholder="Enter Todo Title"
                />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="text"
                name="description"
                value={description}
                onChange={this.handleChange}
                placeholder="Enter Todo description" />
            </FormGroup>
            <FormGroup check>
              <Label for="completed">
                <Input
                  type="checkbox"
                  name="completed"
                  checked={completed}
                  onChange={this.handleChange} />
                Completd
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleClick} name="save-button">
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}


/*
 * component TabList
 * 
 * *********************************/
function TabList(props) {
  function handleClick(e) {
    props.onTabItemClick(e);
  }

  return (
    <div className="my-5 tab-list">
      <span className={props.completeFilter ? "active" : ""} onClick={handleClick} id="complete-tab">
        complete
      </span>
      <span className={props.completeFilter ? "" : "active"} onClick={handleClick} id="incomplete-tab">
        Incomplete
      </span>
    </div>
  );
}


/*
 * component Items
 * 
 * *********************************/
function Items(props) {
  const items = props.items;
  const completeFilter = props.completeFilter;
  const rows = [];

  function handleClick(e) { 
    props.onItemClick(e);
  }

  items.forEach((item) => {
    if (item.completed === completeFilter) {
      rows.push(
        <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
          <span className="todo-title mr-2 complete-todo">
            {item.title}
          </span>
          <span>
            <button className="btn btn-secondary mr-2" onClick={handleClick} value={item.id} name="edit-button">
              {" "}
              Edit{" "}
            </button>
            <button className="btn btn-danger" onClick={handleClick} value={item.id} name="delete-button">
              Delete{" "}
            </button>
          </span>
        </li>
      );
    }
  });

  return (
    <>
    {rows}
    </>
  );
}


/*
 * component App
 * 
 * *********************************/
class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleTabItemClick = this.handleTabItemClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.state = {
      modalOn: false,
      items: [],
      completeFilter: false,
      isEdit: false,
      candidateId: null,

      error: null,
      loading: false
    };
  }

  componentDidMount() {
    this.fetchItems();
  }

  handleClick(e) {
    if (e.target.name === "add-button") {
      this.setState({
        isEdit: false,
        candidateId: null
      });
      this.handleToggleModal();  
    }
  }

  handleTabItemClick(e) {
    if (e.target.id === "complete-tab") {
      this.setState({
        completeFilter: true
      });
    }

    if (e.target.id === "incomplete-tab") {
      this.setState({
        completeFilter: false
      });
    }
  }

  handleItemClick(e) {
    if (e.target.name === "edit-button") {
      this.setState({
        isEdit: true,
        candidateId: parseInt(e.target.value)
      });
      this.handleToggleModal();
    }

    if (e.target.name === "delete-button") {
      const delId = parseInt(e.target.value);
      this.deleteItem(delId);
      this.fetchItems();
    }
  }

  handleSaveButtonClick(e) {
    const candidateId = this.state.candidateId;
    const isEdit = this.state.isEdit;

    if(e.target.name === "save-button") {
      const newItem = {
        id: e.target.item.candidateId,
        title: e.target.item.title,
        description: e.target.item.description,
        completed: e.target.item.completed
      };

      if (isEdit) this.unpdateItem(candidateId, newItem);
      if (!isEdit) this.addItem(newItem);
    }

    this.fetchItems();
    this.handleToggleModal();
  }

  handleToggleModal() {
    if (this.state.modalOn) {
      this.setState({
        candidateId: null,
        isEdit: false
      });
    }

    this.setState( {
        modalOn: !this.state.modalOn
    });
  }

  render() {
    const items = this.state.items;
    const completeFilter = this.state.completeFilter;
    const isEdit = this.state.isEdit;
    const candidateItemIdx = items.findIndex((el) => el.id === this.state.candidateId);
    const candidateItem = (candidateItemIdx === -1) ? null : items[candidateItemIdx];

    const loading = this.state.loading;
    const error = this.state.error;

    return (
      <main className="content">
        <h1 className="text-white text-uppercase text-center my-4">Todo app</h1>
        <div className="row" >
          <div className="col-md-6 col-sm-10 mx-auto p-0">
            <div className="card p-3">
              <div className="">
                <button className="btn btn-primary" onClick={this.handleClick} name="add-button">
                  Add task
                </button>
                {loading ? <span>처리중...</span> : null}
                {error ? <span>에러가 발생했습니다.</span> : null}
              </div>
              <TabList completeFilter={completeFilter} onTabItemClick={this.handleTabItemClick} />
              <ul className="list-group list-group-flush">
                <Items onItemClick={this.handleItemClick} items={items} completeFilter={completeFilter} name={items} />
              </ul>
            </div>
          </div>
        </div>
        {this.state.modalOn ? <CustomModal toggle={this.handleToggleModal} isEdit={isEdit} item={candidateItem} onSaveButtonClick={this.handleSaveButtonClick} /> : null}
      </main>
    );
  }

  // fetchItems
  async fetchItems() {
    try {
      this.setState({
        error: null,
        loading: true
      });
      const response = await axios.get(`https://wittlab.pythonanywhere.com/api/todos/`);
      this.setState({ items: response.data });
    } catch(e) {
      this.setState({ error: e });
    }
    this.setState({ loading: false });
  }

  // updateItem
  async unpdateItem(id, item) {
    try {
      this.setState({
        error: null,
        loading: true
      });
      const response = await axios.put(`https://wittlab.pythonanywhere.com/api/todos/${id}/`, item);
      console.log(response);
      this.fetchItems();
    } catch(e) {
      this.setState({ error: e });
    }
    this.setState({ loading: false });
  }

  // addItem
  async addItem(item) {
    try {
      this.setState({
        error: null,
        loading: true
      });
      const response = await axios.post(`https://wittlab.pythonanywhere.com/api/todos/`, item);
      console.log(response);
      this.fetchItems();
    } catch(e) {
      this.setState({ error: e });
    }
    this.setState({ loading: false });
  }

  // deleteItem
  async deleteItem(id) {
    try {
      this.setState({
        error: null,
        loading: true
      });
      const response = await axios.delete(`https://wittlab.pythonanywhere.com/api/todos/${id}`);
      console.log(response);
      this.fetchItems();
    } catch(e) {
      this.setState({ error: e });
    }
    this.setState({ loading: false });
  }
}


export default App;


// let TODO_ITEM_MAX_ID = 4;
// let TODO_ITEMS = [
// 	{id: 1, title: "할일1", description: "해야할일1", completed: false},
// 	{id: 2, title: "할일2", description: "해야할일2", completed: false},
// 	{id: 3, title: "할일3", description: "해야할일3", completed: true},
// 	{id: 4, title: "할일4", description: "해야할일4", completed: false}
// ];

// function get_TodoList() {
//   return TODO_ITEMS;
// }

// function add_TodoList(newItem) {
//   TODO_ITEM_MAX_ID++;
//   TODO_ITEMS.push({
//     id: TODO_ITEM_MAX_ID,
//     title: newItem.title,
//     description: newItem.description,
//     completed: newItem.completed
//   });
// }

// function update_TodoList(id, newItem) {
//   let editIdx = TODO_ITEMS.findIndex((el) => el.id === id);
//   if (editIdx === -1) {
//     console.log("edit_TodoList fail : no item");
//     return false;
//   }

//   TODO_ITEMS[editIdx] = {
//     id: id,
//     title: newItem.title,
//     description: newItem.description,
//     completed: newItem.completed
//   };
//   return true;
// }

// function del_TodoList(id) {
//   let delIdx = TODO_ITEMS.findIndex((el) => el.id === id);
//   if (delIdx === -1) {
//     console.log("del_TodoList fail : no item");
//     return false;
//   }

//   TODO_ITEMS.splice(delIdx, 1);
//   return true;
// }
